.search-form[data-v-086b1138] {
  background: #FFFFFF;
  padding: 14px 12px;
}
.search-form[data-v-086b1138] .el-form-item--small .el-form-item__content {
  line-height: 28px !important;
}
.search-form[data-v-086b1138] .el-button {
  height: 28px;
  margin-left: 0;
}
.search-form[data-v-086b1138] .el-input__inner {
  height: 28px;
  line-height: 28px;
}
.new-sino-header[data-v-086b1138] {
  height: 56px !important;
}
[data-v-086b1138] .search-form .el-input {
  margin-right: 10px;
}
[data-v-086b1138] .el-button + .el-button {
  margin-left: 0px;
}
